@mixin if-large {
  @media (max-width: 2100px) {
    @content; } }
@mixin if-default {
  @media (max-width: 1660px) {
    @content; } }
@mixin if-lgx {
  @media (max-width: 1380px) {
    @content; } }
@mixin if-lg {
  @media (max-width: 1240px) {
    @content; } }
@mixin if-md {
  @media (max-width: 980px) {
    @content; } }
@mixin if-sm {
  @media (max-width: 767px) {
    @content; } }
@mixin if-xs {
  @media (max-width: 480px) {
    @content; } }
@mixin if-sxs {
  @media (max-width: 320px) {
    @content; } }

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content; }
  &::-moz-placeholder {
    @content; }
  &:-ms-input-placeholder {
    @content; }
  &:-moz-placeholder {
    @content; } }
$offset--xs: 8px;
$offset--sm: 16px;
$offset--md: 20px;
$offset--lg: 26px;

$transition__default: .25s;

$text-size__xs: 11px;
$text-size__sm: 14px;
$text-size__md: 16px;
$text-size__lg: 18px;

$title-size__md: 22px;
