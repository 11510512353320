@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?v7yd7m');
  src:  url('fonts/icomoon.eot?v7yd7m#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?v7yd7m') format('truetype'),
    url('fonts/icomoon.woff?v7yd7m') format('woff'),
    url('fonts/icomoon.svg?v7yd7m#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-process:before {
  content: "\e978";
}
.icon-group:before {
  content: "\e979";
}
.icon-recruitment:before {
  content: "\e97a";
}
.icon-crm:before {
  content: "\e97b";
}
.icon-settings:before {
  content: "\e977";
}
.icon-cancel-icon:before {
  content: "\e96e";
}
.icon-exclamation-icon:before {
  content: "\e972";
}
.icon-success-icon:before {
  content: "\e976";
}
.icon-filter:before {
  content: "\e96d";
  color: #f16e5e;
}
.icon-trash:before {
  content: "\e96f";
  color: #f16e5e;
}
.icon-turn:before {
  content: "\e970";
  color: #f16e5e;
}
.icon-up-down:before {
  content: "\e971";
  color: #aab7b8;
}
.icon-zoom-in:before {
  content: "\e973";
  color: #f16e5e;
}
.icon-zoom-out:before {
  content: "\e974";
  color: #f16e5e;
}
.icon-cross:before {
  content: "\e975";
  color: #f16e5e;
}
.icon-upload:before {
  content: "\e96b";
}
.icon-download:before {
  content: "\e96c";
  color: #fff;
}
.icon-sort-arrows:before {
  content: "\e96a";
  color: #fff;
}
.icon-pagination-arrow:before {
  content: "\e969";
  color: #d5dbdb;
}
.icon-projects-brill:before {
  content: "\e91b";
  color: #d93230;
}
.icon-project-brill--dashed:before {
  content: "\e968";
  color: #aab7b8;
}
.icon-argon-arrow:before {
  content: "\e967";
  color: #26b5ed;
}
.icon-camera:before {
  content: "\e966";
  color: #fff;
}
.icon-avatar-mask:before {
  content: "\e922";
}
.icon-settings-vertical .path1:before {
  content: "\e963";
  color: rgb(246, 111, 91);
}
.icon-settings-vertical .path2:before {
  content: "\e964";
  margin-left: -1em;
  color: rgb(241, 110, 94);
}
.icon-settings-vertical .path3:before {
  content: "\e965";
  margin-left: -1em;
  color: rgb(246, 111, 91);
}
.icon-pencil-2:before {
  content: "\e946";
}
.icon-reply:before {
  content: "\e961";
}
.icon-pin:before {
  content: "\e962";
}
.icon-message-to-user:before {
  content: "\e960";
}
.icon-add-smile:before {
  content: "\e95e";
}
.icon-attach-file:before {
  content: "\e95f";
  color: #f16e5e;
}
.icon-add-member:before {
  content: "\e95d";
  color: #f16e5e;
}
.icon-author-user:before {
  content: "\e95c";
}
.icon-link-icon:before {
  content: "\e957";
  color: #fff;
}
.icon-image-file:before {
  content: "\e958";
}
.icon-document-file:before {
  content: "\e959";
}
.icon-name-singe:before {
  content: "\e95a";
}
.icon-category-set:before {
  content: "\e95b";
  color: #fff;
}
.icon-chanel-lock-icon:before {
  content: "\e955";
}
.icon-chanel-icon:before {
  content: "\e956";
}
.icon-star-empty-project--active:before {
  content: "\e954";
  color: #f79f25;
}
.icon-star-empty-project:before {
  content: "\e953";
}
.icon-add-button2:before {
  content: "\e94b";
}
.icon-user-icon-nav2 .path1:before {
  content: "\e94c";
  color: rgb(213, 219, 219);
}
.icon-user-icon-nav2 .path2:before {
  content: "\e94d";
  margin-left: -1em;
  color: rgb(220, 218, 219);
}
.icon-timer-2-icon:before {
  content: "\e94e";
  color: #d5dbdb;
}
.icon-plus-icon-2:before {
  content: "\e94f";
  color: #879196;
}
.icon-ok-icon-2:before {
  content: "\e950";
  color: #d5dbdb;
}
.icon-talk-cloud-icon:before {
  content: "\e951";
  color: #d5dbdb;
}
.icon-box-icon-2:before {
  content: "\e952";
  color: #d5dbdb;
}
.icon-delete-icon:before {
  content: "\e949";
  color: #d5dbdb;
}
.icon-edit-icon:before {
  content: "\e94a";
}
.icon-toggle-right-sidebar:before {
  content: "\e948";
  color: #aab7b8;
}
.icon-burger-dots-gorizont:before {
  content: "\e947";
  color: #aab7b8;
}
.icon-search-icon2:before {
  content: "\e928";
  color: #aab7b8;
}
.icon-txt-opt:before {
  content: "\67";
}
.icon-hexagon-shape:before {
  content: "\e8fc";
}
.icon-list-stroke:before {
  content: "\e900";
}
.icon-star-outlined:before {
  content: "\e901";
}
.icon-star-filled:before {
  content: "\e902";
}
.icon-search:before {
  content: "\e903";
}
.icon-right-sidebar-toggle:before {
  content: "\e904";
}
.icon-reply-quote:before {
  content: "\e905";
}
.icon-quote:before {
  content: "\e906";
}
.icon-projects:before {
  content: "\e907";
}
.icon-people:before {
  content: "\e908";
}
.icon-paperclip:before {
  content: "\e909";
}
.icon-notification-bell:before {
  content: "\e90a";
}
.icon-more-options:before {
  content: "\e90b";
}
.icon-more-options-outlined:before {
  content: "\e90c";
}
.icon-more-options-filled:before {
  content: "\e90d";
}
.icon-mention-user:before {
  content: "\e90e";
}
.icon-link:before {
  content: "\e90f";
}
.icon-list-fill:before {
  content: "\e910";
}
.icon-hexagon-shape-horizontal:before {
  content: "\e911";
}
.icon-expand-arrow-right:before {
  content: "\e912";
}
.icon-expand-arrow-left:before {
  content: "\e913";
}
.icon-expand-arrow-dn:before {
  content: "\e914";
}
.icon-chat:before {
  content: "\e915";
}
.icon-cells-stroke:before {
  content: "\e916";
}
.icon-cells-fill:before {
  content: "\e917";
}
.icon-filetype-ai:before {
  content: "\e918";
}
.icon-hex-status:before {
  content: "\e919";
}
.icon-plus2:before {
  content: "\e91a";
}
.icon-work-done:before {
  content: "\e91c";
}
.icon-more-options2:before {
  content: "\e91d";
}
.icon-activity:before {
  content: "\e91e";
}
.icon-sexpand-arrow-up:before {
  content: "\e91f";
}
.icon-badge-rightside:before {
  content: "\e920";
}
.icon-tasks:before {
  content: "\e921";
}
.icon-close:before {
  content: "\e923";
}
.icon-badge-leftside:before {
  content: "\e924";
}
.icon-plus:before {
  content: "\e925";
}
.icon-cell-ar-fill:before {
  content: "\e926";
}
.icon-cell-ar-stroke:before {
  content: "\e927";
}
.icon-check:before {
  content: "\e929";
}
.icon-datepick:before {
  content: "\e92a";
}
.icon-add-btn:before {
  content: "\e92b";
}
.icon-hexagon-stroke:before {
  content: "\e92c";
}
.icon-equalizer:before {
  content: "\e92d";
}
.icon-down:before {
  content: "\e92e";
}
.icon-call:before {
  content: "\e92f";
}
.icon-pin-skew:before {
  content: "\e930";
}
.icon-pin-normal:before {
  content: "\e931";
}
.icon-facebook:before {
  content: "\e932";
}
.icon-filetype-image:before {
  content: "\e933";
}
.icon-filetype-other:before {
  content: "\e934";
}
.icon-filetype-pdf:before {
  content: "\e935";
}
.icon-filetype-psd:before {
  content: "\e936";
}
.icon-global:before {
  content: "\e937";
}
.icon-lock:before {
  content: "\e938";
}
.icon-mail:before {
  content: "\e939";
}
.icon-notify-3:before {
  content: "\e93a";
}
.icon-notify-bell:before {
  content: "\e93b";
}
.icon-notify-clock:before {
  content: "\e93c";
}
.icon-notify-info:before {
  content: "\e93d";
}
.icon-skype:before {
  content: "\e93e";
}
.icon-add-fill:before {
  content: "\e93f";
}
.icon-ok:before {
  content: "\e940";
}
.icon-crm1:before {
  content: "\e941";
}
.icon-face-to-face:before {
  content: "\e942";
}
.icon-shape-switch:before {
  content: "\e943";
}
.icon-listview-active:before {
  content: "\e944";
}
.icon-listview-inactive:before {
  content: "\e945";
}
